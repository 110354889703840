/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { getReport, getSalesLogYear } from '../asyncActions/getReportAsyncAction';
import apiStatus from 'utils/apiStatus';
const getReportSlice = createSlice({
  name: 'report',
  initialState: {
    status: null,
    error: null,
    message: null,
    backdrop: null,
    url: '',
    years: []
  },
  reducers: {
    clearStateReport: (state, action) => {
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.url = '';
      state.years = [];
    }
  },
  extraReducers: {
    [getReport.pending]: state => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getReport.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Export report success';
      state.backdrop = null;
      state.url = action.payload;
    },
    [getReport.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },

    [getSalesLogYear.pending]: state => {},
    [getSalesLogYear.fulfilled]: (state, action) => {
      state.years = action.payload;
    },
    [getSalesLogYear.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
    }
  }
});

const { reducer, actions } = getReportSlice;
const { clearStateReport } = actions;
export { clearStateReport };
export default reducer;
