import { salesLogsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';
import { diff, removeEmptyObject } from 'utils/compareDeepObject';

//*thunk action
const getSalesLog = createAsyncThunk(
  'updateSalesLog/getSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await salesLogsApi.getSalesLog(id);
      return response.data;

    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  }
);

const getDropdownConfig = createAsyncThunk(
  'updateSalesLog/getDropdownConfig',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await salesLogsApi.getDropdownConfig();
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  }
);

const checkLockedSalesLog = createAsyncThunk(
  'updateSalesLog/checkLockedSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (dataChecked, props) => {
    try {
      const response = await salesLogsApi.checkLockedSalesLog(dataChecked);
      return response.data;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (dataChecked, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const removeLockedSalesLog = createAsyncThunk(
  'updateSalesLog/removeLockedSalesLog',
  async (editTrackingId, props) => {
    try {
      if (editTrackingId) {
        const response = await salesLogsApi.removeLockedSalesLog(editTrackingId);
        return response.data;
      }
      
      return;
      
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const updateSalesLog = createAsyncThunk(
  'updateSalesLog/updateSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (dataRequest, props) => {
    try {
      const data = dataRequest.value;
      const convertData = { 
        ...data,
        salesLog: { 
          ...data.salesLog,
          approvedPremium: (data.salesLog.approvedPremium ? Number(data.salesLog.approvedPremium) : null),
          annualPayment: (data.salesLog.annualPayment ? Number(data.salesLog.annualPayment) : null),
          targetAnnualPayment: (data.salesLog.targetAnnualPayment ? Number(data.salesLog.targetAnnualPayment) : null),
          quotedPaymentDuration: (data.salesLog.quotedPaymentDuration ? Number(data.salesLog.quotedPaymentDuration) : null),
          approvedPaymentDuration: (data.salesLog.approvedPaymentDuration ? Number(data.salesLog.approvedPaymentDuration) : null),
          approvedFaceAmount: (data.salesLog.approvedFaceAmount ? Number(data.salesLog.approvedFaceAmount) : null),
        },
        insuredPersons: [
          ...data.insuredPersons.map((item) => { 
            return {
              ...item,
              email: (item.email ? item.email : null),
              totalAnnualHouseholdIncome: (item.totalAnnualHouseholdIncome ? Number(item.totalAnnualHouseholdIncome) : null),
              netWorth: (item.netWorth ? Number(item.netWorth) : null),
            } 
          })
        ],
        owner: { 
          ...data.owner,
          email: (data.owner.email ? data.owner.email : null),
        },
        payor: { 
          ...data.payor,
          email: (data.payor.email ? data.payor.email : null),
        },
        newApplication: { 
          ...data.newApplication,
          faceAmount: (data.newApplication.faceAmount ? Number(data.newApplication.faceAmount) : null),
          quotedPremium: (data.newApplication.quotedPremium ? Number(data.newApplication.quotedPremium) : null),
        },
        existingPolicy: { 
          ...data.existingPolicy,
          benefitAmount: (data.existingPolicy.benefitAmount ? Number(data.existingPolicy.benefitAmount) : null),
          faceAmount: (data.existingPolicy.faceAmount ? Number(data.existingPolicy.faceAmount) : null),
        },
        primaryBeneficiarys: [ 
          ...data.primaryBeneficiarys.map((item) => { 
            return {
              ...item, 
              percent: (item.percent ? Number(item.percent) : null),
            } 
          })
        ],
        contingentBeneficiarys: [
          ...data.contingentBeneficiarys.map((item) => { 
            return {
              ...item, 
              percent: (item.percent ? Number(item.percent) : null),
            } 
          })
        ],
        sIITAO: { 
          ...data.sIITAO,
          faceAmount: (data.sIITAO.faceAmount ? Number(data.sIITAO.faceAmount) : null),
        },
        fromPage: dataRequest.fromPage
      }

      let firstDiff = diff(convertData, props.getState().updateSalesLog.salesLog);
      let cleanData = removeEmptyObject(firstDiff);

      const response = await salesLogsApi.postUpdateSalesLog(data.insured.id, cleanData);
      return response.data;
      
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.updateSalesLogStatus === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deleteInsuredPerson = createAsyncThunk(
  'updateSalesLog/deleteInsuredPerson',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      if (data) {
        const response = await salesLogsApi.deleteInsuredPerson(data.insuredPersonsId, data.insuredId);
        return response.data;
      }
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deleteMedicalExam = createAsyncThunk(
  'updateSalesLog/deleteMedicalExam',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      if (data) {
        const response = await salesLogsApi.deleteMedicalExam(data.medicalExamId, data.insuredId);
        return response.data;
      }
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deletePrimaryBeneficiary = createAsyncThunk(
  'updateSalesLog/deletePrimaryBeneficiary',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      if (data) {
        const response = await salesLogsApi.deletePrimaryBeneficiary(data.primaryBeneficiaryId, data.insuredId);
        return response.data;
      }
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deleteContingentBeneficiary = createAsyncThunk(
  'updateSalesLog/deleteContingentBeneficiary',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      if (data) {
        const response = await salesLogsApi.deleteContingentBeneficiary(data.contingentBeneficiaryId, data.insuredId);
        return response.data;
      }
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const updateTimeEditTracking = createAsyncThunk(
  'updateSalesLog/updateTimeEditTracking',
  async (editTrackingId, props) => {
    try {
      if (editTrackingId) {
        const response = await salesLogsApi.updateTimeEditTracking(editTrackingId);
        return response.data;
      }
    
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateSalesLog.updateTimeEditTrackingStatus === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const getInfoAutoCompleteUpdateSalesLog = createAsyncThunk(
  'dataSheetManagement/getCompanies',
  async(data,props) => {
    try {
      const response = await salesLogsApi.getAutoCompleteSalesLog();
      const company = [];
      const sale = [];
      const refer = [];
      const writing = [];
      const processor = [];
      const product = [];
      response.data.companies.forEach(element => {
        const obj = {value:element};
        company.push(obj);
      });
      response.data.saleAgent.forEach(element => {
        const obj = {value:element};
        sale.push(obj);
      });
      response.data.referAgent.forEach(element => {
        const obj = {value:element};
        refer.push(obj);
      });
      response.data.writingAgent.forEach(element => {
        const obj = {value:element};
        writing.push(obj);
      });
      response.data.processorAgent.forEach(element => {
        const obj = {value:element};
        processor.push(obj);
      });
      response.data.product.forEach(element => {
        const obj = {value:element};
        product.push(obj);
      });
      return {
        companies:company,
        saleAgent:sale,
        referAgent:refer,
        writingAgent:writing,
        processorAgent:processor,
        product:product
      };
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  }, {
    condition: (data, {
      getState
    }) => {
      if (getState().createDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
) 


export {
  getSalesLog,
  updateSalesLog,
  deleteInsuredPerson,
  deleteMedicalExam,
  deletePrimaryBeneficiary,
  deleteContingentBeneficiary,
  checkLockedSalesLog,
  removeLockedSalesLog,
  updateTimeEditTracking,
  getInfoAutoCompleteUpdateSalesLog,
  getDropdownConfig
}
