/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

//Layout
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import { AuthGuard } from 'components';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <AuthGuard><Redirect to="/data-sheets/create" /></AuthGuard>
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        // eslint-disable-next-line react/no-multi-comp
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '*/',
    component: DashboardLayout,
    routes: [
      {
        path: '/data-sheets/manage',
        exact: true,
        component: lazy(() => import('views/ManageDataSheet')),
      },
      {
        path: '/data-sheets/create',
        exact: true,
        component: lazy(() => import('views/CreateDataSheet')),
      },
      {
        path: '/data-sheets/update/:id',
        exact: true,
        component: lazy(() => import('views/UpdateDataSheet')),
      },
      {
        path: '/data-sheets/view/:id',
        exact: true,
        component: lazy(() => import('views/ViewDataSheet')),
      },
      {
        path: '/sales-logs/manage',
        exact: true,
        component: lazy(() => import('views/ManageSalesLog')),
      },
      {
        path: '/sales-logs/update/:id',
        exact: true,
        component: lazy(() => import('views/UpdateSalesLog')),
      },
      {
        path: '/sales-logs/view/:id',
        exact: true,
        component: lazy(() => import('views/ViewSalesLog')),
      },
      {
        path: '/report',
        exact: true,
        component: lazy(() => import('views/Report')),
      },
      {
        path: '/log',
        exact: true,
        component: lazy(() => import('views/Log')),
      }
    ]
  }
]

export default routes;
