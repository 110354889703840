import { createSlice } from '@reduxjs/toolkit';
import {
  getDataSheetForUpdate,
  updateDataSheet,
  deleteInsuredPerson,
  deleteMedicalExam,
  deletePrimaryBeneficiary,
  deleteContingentBeneficiary,
  getInfoAutoCompleteUpdateDataSheet,
} from 'store/asyncActions/updateDataSheetAsyncAction';
import apiStatus from 'utils/apiStatus';

//*reducer handle
const updateDataSheetSlice = createSlice({
  name: 'updateDataSheet',
  initialState: {
    updateDataSheetData: null,
    status: null,
    error: null,
    message: null,
    backdrop: null,
    errorCode: null,

    getDataSheetStatus: null,
    infoAutoComplete: null,
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    clearStateUpdateDataSheet: (state, action) => {
      state.updateDataSheetData = null;
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.getDataSheetStatus = null;
      state.errorCode = null;
      state.infoAutoComplete= null;
    }
  },
  extraReducers: {
    //* get data sheet by id
    // eslint-disable-next-line no-unused-vars
    [getDataSheetForUpdate.pending]: (state, action) => {
      state.getDataSheetStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getDataSheetForUpdate.fulfilled]: (state, action) => {
      state.getDataSheetStatus = apiStatus.SUCCESS;
      state.backdrop = null;
      state.updateDataSheetData = action.payload;
    },
    [getDataSheetForUpdate.rejected]: (state, action) => {
      state.getDataSheetStatus = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
      state.errorCode = action.payload.error.statusCode;
    },

    //* update data sheet
    // eslint-disable-next-line no-unused-vars
    [updateDataSheet.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING;
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [updateDataSheet.fulfilled]: (state, action) => {
      state.backdrop = null;
      state.message = 'Update data sheet success';
      state.status = apiStatus.SUCCESS;

      if (action.payload.insuredPersons.length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          insuredPersons: action.payload.insuredPersons
        };
      }
      if (Object.keys(action.payload.insured).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          insured: action.payload.insured
        };
      }
      if (Object.keys(action.payload.existingPolicy).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          existingPolicy: action.payload.existingPolicy
        };
      }
      if (action.payload.contingentBeneficiarys.length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          contingentBeneficiarys: action.payload.contingentBeneficiarys
        };
      }
      if (Object.keys(action.payload.familyMedicalHistory).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          familyMedicalHistory: action.payload.familyMedicalHistory
        };
      }
      if (action.payload.medicalExams.length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          medicalExams: action.payload.medicalExams
        };
      }
      if (Object.keys(action.payload.newApplication).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          newApplication: action.payload.newApplication
        };
      }
      if (Object.keys(action.payload.owner).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          owner: action.payload.owner
        };
      }
      if (Object.keys(action.payload.paymentInformation).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          paymentInformation: action.payload.paymentInformation
        };
      }
      if (Object.keys(action.payload.payor).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          payor: action.payload.payor
        };
      }
      if (action.payload.primaryBeneficiarys.length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          primaryBeneficiarys: action.payload.primaryBeneficiarys
        };
      }
      if (Object.keys(action.payload.primaryPhysician).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          primaryPhysician: action.payload.primaryPhysician
        };
      }
      if (Object.keys(action.payload.sIITAO).length !== 0) {
        state.updateDataSheetData = {
          ...state.updateDataSheetData,
          sIITAO: action.payload.sIITAO
        };
      }
    },
    [updateDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
      state.backdrop = null;
    },

    //* delete insured person
    // eslint-disable-next-line no-unused-vars
    [deleteInsuredPerson.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING;
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteInsuredPerson.fulfilled]: (state, action) => {
      state.backdrop = null;
      const index = state.updateDataSheetData.insuredPersons.findIndex(
        item => item.id === action.payload
      );
      state.updateDataSheetData.insuredPersons.splice(index, 1);

      state.message = 'Delete insured success';
      state.status = apiStatus.SUCCESS;
    },
    [deleteInsuredPerson.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete medical exam
    // eslint-disable-next-line no-unused-vars
    [deleteMedicalExam.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING;
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteMedicalExam.fulfilled]: (state, action) => {
      state.backdrop = null;
      const index = state.updateDataSheetData.medicalExams.findIndex(
        item => item.id === action.payload.id
      );
      state.updateDataSheetData.medicalExams.splice(index, 1);

      state.message = 'Delete medical exam success';
      state.status = apiStatus.SUCCESS;
    },
    [deleteMedicalExam.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.message = 'Delete medical exam fail';
      state.error = action.payload.error.message;
    },

    //* delete primary beneficiary
    // eslint-disable-next-line no-unused-vars
    [deletePrimaryBeneficiary.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING;
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deletePrimaryBeneficiary.fulfilled]: (state, action) => {
      state.backdrop = null;
      const index = state.updateDataSheetData.primaryBeneficiarys.findIndex(
        item => item.id === action.payload.id
      );
      state.updateDataSheetData.primaryBeneficiarys.splice(index, 1);

      state.message = 'Delete primary beneficiary success';
      state.status = apiStatus.SUCCESS;
    },
    [deletePrimaryBeneficiary.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete contingent beneficiary
    // eslint-disable-next-line no-unused-vars
    [deleteContingentBeneficiary.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING;
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteContingentBeneficiary.fulfilled]: (state, action) => {
      state.backdrop = null;
      const index = state.updateDataSheetData.primaryBeneficiarys.findIndex(
        item => item.id === action.payload.id
      );
      state.updateDataSheetData.contingentBeneficiarys.splice(index, 1);

      state.message = 'Delete contingent beneficiary success';
      state.status = apiStatus.SUCCESS;
    },
    [deleteContingentBeneficiary.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* handle get companies
    // eslint-disable-next-line no-unused-vars
    [getInfoAutoCompleteUpdateDataSheet.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getInfoAutoCompleteUpdateDataSheet.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = apiStatus.SUCCESS;
      state.message = 'Get info auto complete success';
      state.infoAutoComplete = action.payload;
    },
    [getInfoAutoCompleteUpdateDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
      state.backdrop = apiStatus.SUCCESS;
    },  
  }
});

const { actions, reducer } = updateDataSheetSlice;
const {
  clearStateUpdateDataSheet
} = actions;

export {
  clearStateUpdateDataSheet
};
export default reducer;
