import { dataSheetsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

//*thunk action
const getDataSheets = createAsyncThunk(
  'manageDataSheet/getDataSheets',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await dataSheetsApi.getDataSheets();

      const listDataSheets = response.data.map((item) => {
        return {
          id: item.id,
          fullName: item.insuredPerson.firstName + ' ' + item.insuredPerson.middleName + ' ' + item.insuredPerson.lastName,
          dob: item.insuredPerson.dateOfBirth ? item.insuredPerson.dateOfBirth : null,
          company: item.newApplication.company,
          product: item.newApplication.product,
          faceAmount: item.newApplication.faceAmount,
          status: 'In Process',
          creationUserId: item.creationUserId,
        }
      });
  
      return listDataSheets;
    
    } catch (err) {
      const newError = { ...err }

      const payload = { 
        error: newError.response.data
      }
      return props.rejectWithValue(payload);
    }
  }
);

const submitDataSheet = createAsyncThunk(
  'manageDataSheet/submitDataSheet',
  async (id, props) => {
    try{
      const response = await dataSheetsApi.submitDataSheet(id);
      return response.data;
    } catch(err){
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
)

const duplicateDataSheet = createAsyncThunk(
  'manageDataSheet/duplicateDataSheet',
  async (id,props) => {
    try{
      const response = await dataSheetsApi.duplicateDataSheet(id);
      return {
        id: response.data.insured.id,
        fullName: response.data.insuredPersons[0].firstName + ' ' + response.data.insuredPersons[0].middleName + ' ' + response.data.insuredPersons[0].lastName,
        dob: response.data.insuredPersons[0].dateOfBirth ? response.data.insuredPersons[0].dateOfBirth : null,
        company: response.data.newApplication.company,
        product: response.data.newApplication.product,
        faceAmount: response.data.newApplication.faceAmount,
        status: 'In Process',
        creationUserId: response.data.insured.creationUserId,
      };
    } catch(err){
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
)

const deleteDataSheet = createAsyncThunk(
  'manageDataSheet/deleteDataSheet',
  async (id,props) => {
    try{
      const response = await dataSheetsApi.deleteDataSheet(id);
      return response.data;
    } catch(err){
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
)

export {
  getDataSheets,
  submitDataSheet,
  duplicateDataSheet,
  deleteDataSheet
}
