/* eslint-disable no-unused-vars */
import axios from 'apis/axiosClient';

const getSalesLogs = () => {
  return axios.get('/sales-logs/');
};

const getSalesLog = insuredId => {
  return axios.get(`/sales-logs/${insuredId}`);
};

const postUpdateSalesLog = (insuredId, salesLogData) => {
  return axios.patch(`/sales-logs/${insuredId}`, salesLogData);
};

const postUpdateSalesLogCell = (insuredId, salesLogCellData) => {
  return axios.patch(`/sales-logs/${insuredId}/cell`, salesLogCellData);
};

const postRefreshSalesLog = insuredId => {
  return axios.delete(`/edit-tracking/insured/${insuredId}`);
};

const downloadSalesLog = insuredId => {
  return axios.post(`/sales-logs/insureds/${insuredId}`);
};

const duplicateSalesLog = insuredId => {
  return axios.post(`/sales-logs/insureds/${insuredId}/duplicate`);
};

const checkLockedSalesLog = dataChecked => {
  return axios.post('/edit-tracking/', dataChecked);
};

const removeLockedSalesLog = editTrackingId => {
  return axios.delete(`/edit-tracking/${editTrackingId}`);
};

const updateTimeEditTracking = editTrackingId => {
  return axios.patch(`/edit-tracking/${editTrackingId}`);
};

const deleteInsuredPerson = (insuredPersonId, insuredId) => {
  return axios.delete(`insured-persons/${insuredPersonId}/insureds/${insuredId}?deleteFrom=salesLog`);
};

const deleteMedicalExam = (medicalExamId, insuredId) => {
  return axios.delete(`medical-exams/${medicalExamId}/insureds/${insuredId}?deleteFrom=salesLog`);
};

const deletePrimaryBeneficiary = (primaryBeneficiaryId, insuredId) => {
  return axios.delete(`primary-beneficiarys/${primaryBeneficiaryId}/insureds/${insuredId}?deleteFrom=salesLog`);
};

const deleteContingentBeneficiary = (contingentBeneficiaryId, insuredId) => {
  return axios.delete(`contingent-beneficiarys/${contingentBeneficiaryId}/insureds/${insuredId}?deleteFrom=salesLog`);
};

const getAutoCompleteSalesLog = () => {
  return axios.get('/data-sheets/auto-complete/agent');
}

const getDropdownConfig = () => {
  return axios.get('/sales-logs/dropdownConfig');
}

const getSalesLogYear = () => {
  return axios.get('/sales-logs/year');
};

const deleteSalesLog = (id) => {
  return axios.delete(`/sales-logs/${id}`);
};

export default {
  getSalesLog,
  getSalesLogs,
  postUpdateSalesLog,
  postUpdateSalesLogCell,
  postRefreshSalesLog,
  downloadSalesLog,
  duplicateSalesLog,
  deleteInsuredPerson,
  deleteMedicalExam,
  deletePrimaryBeneficiary,
  deleteContingentBeneficiary,
  checkLockedSalesLog,
  removeLockedSalesLog,
  updateTimeEditTracking,
  getAutoCompleteSalesLog,
  getDropdownConfig,
  getSalesLogYear,
  deleteSalesLog
};
