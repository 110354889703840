import { salesLogsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';

//*thunk action
const getSalesLog = createAsyncThunk(
  'viewSalesLog/getSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await salesLogsApi.getSalesLog(id);
      return response.data;
      
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  }
);




export {
  getSalesLog,
}
