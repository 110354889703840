import { dataSheetsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';
import { diff, removeEmptyObject } from 'utils/compareDeepObject';

const getDataSheetForUpdate = createAsyncThunk(
  'dataSheetManagement/getDataSheetForUpdate',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await dataSheetsApi.getListDataSheetById(id);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  }
);

//*thunk action
const updateDataSheet = createAsyncThunk(
  'dataSheetManagement/updateDataSheet',
  // eslint-disable-next-line no-unused-vars
  async ({ data, id }, props) => {
    try {
      const convertData = {
        ...data,
        insuredPersons: [
          ...data.insuredPersons.map(item => {
            return {
              ...item,
              totalAnnualHouseholdIncome: item.totalAnnualHouseholdIncome
                ? Number(item.totalAnnualHouseholdIncome)
                : null,
              netWorth: item.netWorth ? Number(item.netWorth) : null,
              email: item.email === '' ? (item.email = null) : item.email
            };
          })
        ],
        owner: {
          ...data.owner,
          email:
            data.owner.email === ''
              ? (data.owner.email = null)
              : data.owner.email
        },
        payor: {
          ...data.payor,
          email:
            data.payor.email === ''
              ? (data.payor.email = null)
              : data.payor.email
        },
        newApplication: {
          ...data.newApplication,
          faceAmount: data.newApplication.faceAmount
            ? Number(data.newApplication.faceAmount)
            : null,
          quotedPremium: data.newApplication.quotedPremium
            ? Number(data.newApplication.quotedPremium)
            : null
        },
        existingPolicy: {
          ...data.existingPolicy,
          benefitAmount: data.existingPolicy.benefitAmount
            ? Number(data.existingPolicy.benefitAmount)
            : null,
          faceAmount: data.existingPolicy.faceAmount
            ? Number(data.existingPolicy.faceAmount)
            : null
        },
        primaryBeneficiarys: [
          ...data.primaryBeneficiarys.map(item => {
            return {
              ...item,
              percent: item.percent ? Number(item.percent) : null
            };
          })
        ],
        contingentBeneficiarys: [
          ...data.contingentBeneficiarys.map(item => {
            return {
              ...item,
              percent: item.percent ? Number(item.percent) : null
            };
          })
        ],
        sIITAO: {
          ...data.sIITAO,
          faceAmount: data.sIITAO.faceAmount
            ? Number(data.sIITAO.faceAmount)
            : null
        }
      };
      let firstDiff = diff(
        convertData,
        props.getState().updateDataSheet.updateDataSheetData
      );
      let cleanData = removeEmptyObject(firstDiff);

      const response = await dataSheetsApi.updateDataSheet(cleanData, id);

      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deleteInsuredPerson = createAsyncThunk(
  'dataSheetManagement/deleteInsuredPersons',
  async (data, props) => {
    try {
      const response = await dataSheetsApi.deleteInsuredPerson(data.insuredPersonId, data.insuredId);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false
  }
);
const deleteMedicalExam = createAsyncThunk(
  'dataSheetManagement/deleteMedicalExam',
  async (data, props) => {
    try {
      const response = await dataSheetsApi.deleteMedicalExam(data.medicalExamId, data.insuredId);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false
  }
);
const deletePrimaryBeneficiary = createAsyncThunk(
  'dataSheetManagement/deletePrimaryBeneficary',
  async (data, props) => {
    try {
      const response = await dataSheetsApi.deletePrimaryBeneficiary(data.primaryBeneficiaryId, data.insuredId);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false
  }
);
const deleteContingentBeneficiary = createAsyncThunk(
  'dataSheetManagement/deleteContingentBeneficiary',
  async (data, props) => {
    try {
      const response = await dataSheetsApi.deleteContingentBeneficiary(data.contingentBeneficiaryId, data.insuredId);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false
  }
);

const checkLockedDataSheet = createAsyncThunk(
  'dataSheetManagement/checkLockedDataSheet',
  // eslint-disable-next-line no-unused-vars
  async (dataChecked, props) => {
    try {
      const response = await dataSheetsApi.checkLockedDataSheet(dataChecked);
      return response.data;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (dataChecked, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const removeLockedDataSheet = createAsyncThunk(
  'dataSheetManagement/removeLockedDataSheet',
  async (data, props) => {
    try {
      const editTracking = props.getState().updateDataSheet.editTracking;

      if (editTracking) {
        const response = await dataSheetsApi.removeLockedDataSheet(editTracking.id);
        return response.data;
      }

      return;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const updateTimeEditTracking = createAsyncThunk(
  'dataSheetManagement/updateTimeEditTracking',
  async (data, props) => {
    try {
      const editTracking = props.getState().updateDataSheet.editTracking;

      if (editTracking) {
        const response = await dataSheetsApi.updateTimeEditTracking(
          editTracking.id
        );
        return response.data;
      }
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().updateDataSheet.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);


const getInfoAutoCompleteUpdateDataSheet = createAsyncThunk(
  'dataSheetManagement/getCompanies',
  async(data,props) => {
    try {
      const response = await dataSheetsApi.getAutoComplete();
      const company = [];
      const sale = [];
      const refer = [];
      const writing = [];
      const processor = [];
      const product = [];
      response.data.companies.forEach(element => {
        const obj = {value:element};
        company.push(obj);
      });
      response.data.saleAgent.forEach(element => {
        const obj = {value:element};
        sale.push(obj);
      });
      response.data.referAgent.forEach(element => {
        const obj = {value:element};
        refer.push(obj);
      });
      response.data.writingAgent.forEach(element => {
        const obj = {value:element};
        writing.push(obj);
      });
      response.data.processorAgent.forEach(element => {
        const obj = {value:element};
        processor.push(obj);
      });
      response.data.product.forEach(element => {
        const obj = {value:element};
        product.push(obj);
      });
      return {
        companies:company,
        saleAgent:sale,
        referAgent:refer,
        writingAgent:writing,
        processorAgent:processor,
        product:product
      };
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  }, {
    condition: (data, {
      getState
    }) => {
      if (getState().createDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
) 

export {
  updateDataSheet,
  getDataSheetForUpdate,
  deleteContingentBeneficiary,
  deletePrimaryBeneficiary,
  deleteMedicalExam,
  deleteInsuredPerson,
  checkLockedDataSheet,
  updateTimeEditTracking,
  removeLockedDataSheet,
  getInfoAutoCompleteUpdateDataSheet
};
