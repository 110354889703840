const 
  CREATE_DATA_SHEET = 'create_data_sheet',
  VIEW_ALL_DATA_SHEET = 'view_all_data_sheet',
  DUPLICATE_DATA_SHEET = 'duplicate_data_sheet',
  UPDATE_DATA_SHEET = 'update_data_sheet',
  CAN_UPDATE_ANY_DATA_SHEET = 'can_update_any_data_sheet',
  VIEW_A_DATA_SHEET = 'view_a_data_sheet',
  SUBMIT_DATA_SHEET = 'submit_data_sheet',
  CAN_SUBMIT_ANY_DATA_SHEET = 'can_submit_any_data_sheet',
  DELETE_DATA_SHEET = 'delete_data_sheet',
  CAN_DELETE_ANY_DATA_SHEET = 'can_delete_any_data_sheet',

  DELETE_INSURED_PERSON = 'delete_insured_person',
  DELETE_MEDICAL_EXAM = 'delete_medical_exam',
  DELETE_PRIMARY_BENEFICIARY = 'delete_primary_beneficiary',
  DELETE_CONTINGENT_BENEFICIARY = 'delete_contingent_beneficiary',

  VIEW_ALL_SALES_LOG = 'view_all_sales_log',
  DUPLICATE_SALES_LOG = 'duplicate_sales_log',
  UPDATE_SALES_LOG = 'update_sales_log',
  ENHANCED_UPDATE_SALES_LOG = 'enhanced_update_sales_log',
  UPDATE_SALES_LOG_CELL = 'update_sales_log_cell',
  VIEW_A_SALES_LOG = 'view_a_sales_log',
  DOWNLOAD_SALES_LOG = 'download_sales_log',
  REFRESH_SALES_LOG = 'refresh_sales_log',
  DELETE_SALES_LOG = 'delete_sales_log',

  GET_REPORT = 'get_report',

  VIEW_ACTIVITY_LOG = 'view_activity_log';

  
export default {
  CREATE_DATA_SHEET,
  VIEW_ALL_DATA_SHEET,
  DUPLICATE_DATA_SHEET,
  UPDATE_DATA_SHEET,
  CAN_UPDATE_ANY_DATA_SHEET,
  VIEW_A_DATA_SHEET,
  SUBMIT_DATA_SHEET,
  CAN_SUBMIT_ANY_DATA_SHEET,
  DELETE_DATA_SHEET,
  CAN_DELETE_ANY_DATA_SHEET,

  DELETE_INSURED_PERSON,
  DELETE_MEDICAL_EXAM,
  DELETE_PRIMARY_BENEFICIARY,
  DELETE_CONTINGENT_BENEFICIARY,

  VIEW_ALL_SALES_LOG,
  DUPLICATE_SALES_LOG,
  UPDATE_SALES_LOG,
  ENHANCED_UPDATE_SALES_LOG,
  UPDATE_SALES_LOG_CELL,
  VIEW_A_SALES_LOG,
  DOWNLOAD_SALES_LOG,
  REFRESH_SALES_LOG,
  DELETE_SALES_LOG,

  GET_REPORT,

  VIEW_ACTIVITY_LOG,
}
