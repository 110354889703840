import { createSlice } from '@reduxjs/toolkit';
import { 
  getDataSheets, 
  submitDataSheet, 
  duplicateDataSheet,
  deleteDataSheet
} from 'store/asyncActions/manageDataSheetAsyncAction';
import apiStatus from 'utils/apiStatus';

//*reducer handle
const manageDataSheetSlice = createSlice({
  name: 'manageDataSheet',
  initialState: {
    status: null,
    error: null,
    message: null,
    backdrop: null,

    getDataSheetsStatus: null,
    getDataSheetsError: null,
    idToRemove: null,

    newDataSheet: null,

    dataSheets: [],
    dataSheetId:null
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    clearStateManageDataSheet: (state, action) => {
      state.dataSheets = [];
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.dataSheetId = null;
      state.idToRemove = null;

      state.newDataSheet = null;

      state.getDataSheetsStatus = null;
      state.getDataSheetsError = null;
    },
  },
  extraReducers: {
    //*Get list data sheet
    // eslint-disable-next-line no-unused-vars
    [getDataSheets.pending]: (state, action) => {
      state.getDataSheetsStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getDataSheets.fulfilled]: (state, action) => {
      state.getDataSheetsStatus = apiStatus.SUCCESS;
      state.backdrop = null
      state.dataSheets = action.payload;
    },
    [getDataSheets.rejected]: (state, action) => {
      state.getDataSheetsStatus = apiStatus.ERROR;
      state.backdrop = null
      state.getDataSheetsError = action.payload.error.message;
    },

    //*Submit data sheet
    // eslint-disable-next-line no-unused-vars
    [submitDataSheet.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [submitDataSheet.fulfilled]: (state, action) => {
      // const findIndexDataSheets = (value) => {
      //   return value.id === action.payload.id
      // };
      // const index = state.dataSheets.findIndex(findIndexDataSheets);

      // state.dataSheets.splice(index,1);
      state.status = apiStatus.SUCCESS;
      state.idToRemove = action.payload.id;
      state.message = 'Submit data sheet success';
      state.backdrop = null;
    },
    [submitDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },
    
    //*Duplicate data sheet
    // eslint-disable-next-line no-unused-vars
    [duplicateDataSheet.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [duplicateDataSheet.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = null;
      state.message = 'Create Copy data sheet success';
      state.newDataSheet = action.payload; 
      state.dataSheetId = action.payload.id;
    },
    [duplicateDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },

    //*Delete data sheet
    // eslint-disable-next-line no-unused-vars
    [deleteDataSheet.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [deleteDataSheet.fulfilled]: (state, action) => {
      // const findIndexDataSheets = (value) => {
      //   return value.id === action.payload.id
      // };
      // const index = state.dataSheets.findIndex(findIndexDataSheets);

      // state.dataSheets.splice(index,1);
      state.status = apiStatus.SUCCESS;
      state.idToRemove = action.payload.id;
      state.message = 'Delete data sheet success';
      state.backdrop = null;
    },
    [deleteDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },
  }
});

const { actions, reducer } = manageDataSheetSlice;
const { 
  clearStateManageDataSheet
} = actions;

export {
  clearStateManageDataSheet
}
export default reducer;
