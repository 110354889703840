import { createSlice } from '@reduxjs/toolkit';
import {
  getSalesLogs,
  updateSalesLogCell,
  checkLockedSalesLog,
  removeLockedSalesLog,
  refreshSalesLog,
  downloadSalesLog,
  updateTimeEditTracking,
  duplicateSalesLog,
  getDropdownConfig,
  deleteSalesLog
} from 'store/asyncActions/manageSalesLogAsyncAction';
import apiStatus from 'utils/apiStatus';

//*reducer handle
const manageSalesLogSlice = createSlice({
  name: 'manageSalesLog',
  initialState: {
    status: null,
    error: null,
    message: null,

    salesLogDuplicateId: null,
    salesLogs: [],
    dropdownConfig: [],
    backdrop: null,
    editTracking: { id: null },
    url: '',

    newSalesLog: null,

    getSalesLogsStatus: null,
    getSalesLogsError: null,

    updateTimeEditTrackingStatus: null,
    updateTimeEditTrackingError: null,

    salesLogRemoveId: ''
  },
  reducers: {

    // eslint-disable-next-line no-unused-vars
    clearEditTrackingManageSalesLog: (state, action) => {
      state.editTracking = { id: null };
    },

    // eslint-disable-next-line no-unused-vars
    clearStateManageSalesLog: (state, action) => {
      state.salesLogDuplicateId = null;
      state.salesLogs = [];
      state.dropdownConfig = [];
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.editTracking = { id: null };
      state.url = '';

      state.newSalesLog = null;

      state.getSalesLogsStatus = null;
      state.getSalesLogsError = null;

      state.updateTimeEditTrackingStatus = null;
      state.updateTimeEditTrackingError = null;
      state.salesLogRemoveId = '';
    }
  },
  extraReducers: {
    //* get list sales log
    // eslint-disable-next-line no-unused-vars
    [getSalesLogs.pending]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getSalesLogs.fulfilled]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.SUCCESS;
      state.backdrop = null;
      state.salesLogs = action.payload;
    },
    [getSalesLogs.rejected]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.ERROR;
      state.backdrop = null;
      state.getSalesLogsError = action.payload.error.message;
    },

    //* update sales log cell
    // eslint-disable-next-line no-unused-vars
    [updateSalesLogCell.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [updateSalesLogCell.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Update sales log cell success';
      const index = state.salesLogs.findIndex(
        item => item.id === action.payload.insuredId
      );
      state.salesLogs[index][action.payload.fieldName] = action.payload.value;
    },
    [updateSalesLogCell.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* refresh sales log
    // eslint-disable-next-line no-unused-vars
    [refreshSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [refreshSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Refresh sales log success';
    },
    [refreshSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },
    
    //* download sales log
    // eslint-disable-next-line no-unused-vars
    [downloadSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [downloadSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Download sale log success';
      state.url = action.payload;
      state.backdrop = null;
    },
    [downloadSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* duplicate sales log
    // eslint-disable-next-line no-unused-vars
    [duplicateSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [duplicateSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Create copy sale log success';
      state.newSalesLog = action.payload;
      state.salesLogs.unshift(action.payload);
      state.salesLogDuplicateId = action.payload.id;
    },
    [duplicateSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* duplicate sales log
    // eslint-disable-next-line no-unused-vars
    [deleteSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Delete sale log success';
      state.salesLogRemoveId = action.payload.id;
      state.salesLogs = state.salesLogs.filter(item => {
        if(item.id === action.payload.id){
          return false;
        } return true;
      });
    },
    [deleteSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* check locked sales log
    // eslint-disable-next-line no-unused-vars
    [checkLockedSalesLog.pending]: (state, action) => {
    },
    // eslint-disable-next-line no-unused-vars
    [checkLockedSalesLog.fulfilled]: (state, action) => {
      state.editTracking = action.payload.isReadyToUpdate ? action.payload.editTracking : { id: null };
    },
    [checkLockedSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* remove locked sales log
    // eslint-disable-next-line no-unused-vars
    [removeLockedSalesLog.pending]: (state, action) => {
    },
    // eslint-disable-next-line no-unused-vars
    [removeLockedSalesLog.fulfilled]: (state, action) => {
      state.editTracking = { id: null };
    },
    [removeLockedSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* update time edit tracking
    // eslint-disable-next-line no-unused-vars
    [updateTimeEditTracking.pending]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [updateTimeEditTracking.fulfilled]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.SUCCESS;
    },
    [updateTimeEditTracking.rejected]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.ERROR;
      state.updateTimeEditTrackingError = action.payload.error.message;
    },

    //* get list sales log
    // eslint-disable-next-line no-unused-vars
    [getDropdownConfig.pending]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getDropdownConfig.fulfilled]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.SUCCESS;
      state.backdrop = null;
      let dataDropdown = {
        carrier: [],
        product: [],
        saleAgent: [],
        writingAgent: [],
        usProcessor: [],
        vnProcessor: []
      }
      if(action.payload.length > 0) {
        action.payload.forEach(item => {
          if(item.type === 'carrier'){
            if(item.value === 'N/A') {
              dataDropdown.carrier.unshift(item.value)
            } else {
              dataDropdown.carrier.push(item.value)
            }
          }
          if(item.type === 'product'){
            if(item.value === 'N/A') {
              dataDropdown.product.unshift(item.value)
            } else {
              dataDropdown.product.push(item.value)
            }
          }
          if(item.type === 'saleAgent'){
            if(item.value === 'N/A') {
              dataDropdown.saleAgent.unshift(item.value)
            } else {
              dataDropdown.saleAgent.push(item.value)
            }
          }
          if(item.type === 'writingAgent'){
            if(item.value === 'N/A') {
              dataDropdown.writingAgent.unshift(item.value)
            } else {
              dataDropdown.writingAgent.push(item.value)
            }
          }
          if(item.type === 'usProcessor'){
            if(item.value === 'N/A') {
              dataDropdown.usProcessor.unshift(item.value)
            } else {
              dataDropdown.usProcessor.push(item.value)
            }
          }
          if(item.type === 'vnProcessor'){
            if(item.value === 'N/A') {
              dataDropdown.vnProcessor.unshift(item.value)
            } else {
              dataDropdown.vnProcessor.push(item.value)
            }
          }
        })
      }
      state.dropdownConfig = dataDropdown;
    },
    [getDropdownConfig.rejected]: (state, action) => {
      state.getSalesLogsStatus = apiStatus.ERROR;
      state.backdrop = null;
      state.getSalesLogsError = action.payload.error.message;
    },
  }
});

const { actions, reducer } = manageSalesLogSlice;

const { 
  clearStateManageSalesLog,
  clearEditTrackingManageSalesLog
} = actions;

export { 
  clearStateManageSalesLog,
  clearEditTrackingManageSalesLog
};
export default reducer;
