import validatePermission from 'utils/validatePermission';
import permissionAuth from 'utils/permissionAuth';

export default (permissions=[]) => {
  return [
    validatePermission(permissions, ['or', permissionAuth.VIEW_ALL_DATA_SHEET, permissionAuth.CREATE_DATA_SHEET]) ? null :
      {
        title: 'DATA SHEET', //* VIEW_ALL_DATA_SHEET || CREATE_DATA_SHEET
        href: '/data-sheets',
        children: [
          validatePermission(permissions, [permissionAuth.VIEW_ALL_DATA_SHEET]) ? null :
            {
              title: 'MANAGE DATA SHEET', //* VIEW_ALL_DATA_SHEET
              href: '/data-sheets/manage', 
            },
          validatePermission(permissions, [permissionAuth.CREATE_DATA_SHEET]) ? null :  
            {
              title: 'CREATE DATA SHEET', //* CREATE_DATA_SHEET
              href: '/data-sheets/create',
            },
        ].filter(element => element !== null)
      },

    validatePermission(permissions, [permissionAuth.VIEW_ALL_SALES_LOG]) ? null :  
      {
        title: 'SALES LOG', //* VIEW_ALL_SALES_LOG
        href: '/sales-logs/manage',
      },

    validatePermission(permissions, [permissionAuth.GET_REPORT]) ? null :  
      {
        title: 'REPORT', //* GET_REPORT
        href: '/report',
      },
    
    validatePermission(permissions, [permissionAuth.VIEW_ACTIVITY_LOG]) ? null :  
      {
        title: 'LOG', //* VIEW_ACTIVITY_LOG
        href: '/log',
      },
  ].filter(element => element !== null);
};
