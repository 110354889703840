import { dataSheetsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';

//*thunk action
const getDataSheetToView = createAsyncThunk(
  'viewManageDataSheet/getDataSheet',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {    
    try {
      const response = await dataSheetsApi.getListDataSheetById(id, 'view');
      return response.data;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  }
);

export {
  getDataSheetToView,
}
