import { createSlice } from '@reduxjs/toolkit';
import { createDataSheet, getInfoAutoComplete } from 'store/asyncActions/createDataSheetAsyncAction';
import apiStatus from 'utils/apiStatus'

//*reducer handle
const createDataSheetSlice = createSlice({
  name: 'createDataSheet',
  initialState: {
    status: null,
    error: null,
    message: null,
    backdrop: null,
    infoAutoComplete: null,
  },
  reducers: {
    clearStateCreateDataSheet: (state, action) => {
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.infoAutoComplete = null;
    }
  },
  extraReducers: {
    [createDataSheet.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [createDataSheet.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = apiStatus.SUCCESS;
      state.message = 'Save data sheet success';
    },
    [createDataSheet.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
      state.backdrop = apiStatus.SUCCESS;
    },
    [getInfoAutoComplete.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getInfoAutoComplete.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = apiStatus.SUCCESS;
      state.message = 'Get info auto complete success ';
      state.infoAutoComplete = action.payload;
    },
    [getInfoAutoComplete.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
      state.backdrop = apiStatus.SUCCESS;
    },  
  }
});

const { actions,reducer } = createDataSheetSlice;
const { clearStateCreateDataSheet } = actions;

export {
  clearStateCreateDataSheet
}
export default reducer;
