import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportApi, salesLogsApi } from '../../apis';
import apiStatus from 'utils/apiStatus';
const getReport = createAsyncThunk(
  'report/getReport',
  async (params, props) => {
    try {
      const response = await reportApi.getReport(params);
      return response.data;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().report.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const getSalesLogYear = createAsyncThunk(
  'report/getSalesLogYear',
  async (params, props) => {
    try {
      const response = await salesLogsApi.getSalesLogYear(params);
      return response.data;

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().report.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);
export { getReport, getSalesLogYear };
