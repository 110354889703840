import {
  dataSheetsApi
} from 'apis';
import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus'

//*thunk action
const createDataSheet = createAsyncThunk(
  'dataSheetManagement/createDataSheet',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try{
      const convertData = {
        ...data,
        insuredPersons: [
          ...data.insuredPersons.map((item) => {
            return {
              ...item,
              totalAnnualHouseholdIncome: (item.totalAnnualHouseholdIncome ? Number(item.totalAnnualHouseholdIncome) : null),
              netWorth: (item.netWorth ? Number(item.netWorth) : null),
              isPrimary: (item.isPrimary ? Boolean(item.isPrimary) : null),
              email: (item.email === '' ? item.email = null : item.email)
            }
          })
        ],
        owner: {
          ...data.owner,
          email: (data.owner.email === '' ? data.owner.email = null : data.owner.email),
        },
        payor: {
          ...data.payor,
          email: (data.payor.email === '' ? data.payor.email = null : data.payor.email),
        },
        newApplication: {
          ...data.newApplication,
          faceAmount: (data.newApplication.faceAmount ? Number(data.newApplication.faceAmount) : null),
          quotedPremium: (data.newApplication.quotedPremium ? Number(data.newApplication.quotedPremium) : null),
        },
        existingPolicy: {
          ...data.existingPolicy,
          benefitAmount: (data.existingPolicy.benefitAmount ? Number(data.existingPolicy.benefitAmount) : null),
          faceAmount: (data.existingPolicy.faceAmount ? Number(data.existingPolicy.faceAmount) : null),
        },
        primaryBeneficiarys: [
          ...data.primaryBeneficiarys.map((item) => {
            return {
              ...item,
              percent: (item.percent ? Number(item.percent) : null),
            }
          })
        ],
        contingentBeneficiarys: [
          ...data.contingentBeneficiarys.map((item) => {
            return {
              ...item,
              percent: (item.percent ? Number(item.percent) : null),
            }
          })
        ],
        sIITAO: {
          ...data.sIITAO,
          faceAmount: (data.sIITAO.faceAmount ? Number(data.sIITAO.faceAmount) : null),
        },
      }
      await dataSheetsApi.createDataSheet(convertData);
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }

  }, {
    condition: (data, {
      getState
    }) => {
      if (getState().createDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const getInfoAutoComplete = createAsyncThunk(
  'dataSheetManagement/getCompanies',
  async(data,props) => {
    try {
      const response = await dataSheetsApi.getAutoComplete();
      const company = [];
      const sale = [];
      const refer = [];
      const writing = [];
      const processor = [];
      const product = [];
      response.data.companies.forEach(element => {
        const obj = {value:element};
        company.push(obj);
      });
      response.data.saleAgent.forEach(element => {
        const obj = {value:element};
        sale.push(obj);
      });
      response.data.referAgent.forEach(element => {
        const obj = {value:element};
        refer.push(obj);
      });
      response.data.writingAgent.forEach(element => {
        const obj = {value:element};
        writing.push(obj);
      });
      response.data.processorAgent.forEach(element => {
        const obj = {value:element};
        processor.push(obj);
      });
      response.data.product.forEach(element => {
        const obj = {value:element};
        product.push(obj);
      });
      return {
        companies:company,
        saleAgent:sale,
        referAgent:refer,
        writingAgent:writing,
        processorAgent:processor,
        product:product
      };
    } catch (err) {
      const newError = { ...err }
      const payload = { error: newError.response.data }
      return props.rejectWithValue(payload);
    }
  }, {
    condition: (data, {
      getState
    }) => {
      if (getState().createDataSheet.status === apiStatus.PENDING) {
        return false
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
) 

export {
  createDataSheet,
  getInfoAutoComplete
}
