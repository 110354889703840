import { createSlice } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

import {
  getDataSheetToView,
} from 'store/asyncActions/viewDataSheetAsyncAction';

//*reducer handle
const viewDataSheetSlice = createSlice({
  name: 'manageDataSheet/viewDataSheet',
  initialState: {
    status: null,
    error: null,
    message: null,

    backdrop: null,
    dataSheet: null,
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    clearStateViewDataSheet: (state, action) => {
      state.dataSheet = null;
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
    },
  },
  extraReducers: {
    //* get sales log by id
    // eslint-disable-next-line no-unused-vars
    [getDataSheetToView.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING; 
    },
    [getDataSheetToView.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = null;
      state.dataSheet = action.payload;
    },
    [getDataSheetToView.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },
  }
});

const { actions, reducer } = viewDataSheetSlice;
const { 
  clearStateViewDataSheet,
} = actions;

export {
  clearStateViewDataSheet,
}
export default reducer;
