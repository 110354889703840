import manageDataSheetReducer from 'store/slices/manageDataSheetSlice';
import manageSalesLogReducer from 'store/slices/manageSalesLogSlice';
import updateSalesLogReducer from 'store/slices/updateSalesLogSlice';
import viewSalesLogReducer from 'store/slices/viewSalesLogSlice';
import createDataSheetReducer from 'store/slices/createDataSheetSlice';
import updateDataSheetReducer from 'store/slices/updateDataSheetSlice';
import getReportReducer from 'store/slices/getReportSlice';
import viewDataSheetReducer from 'store/slices/viewDataSheetSlice';
import sessionReducer from 'store/slices/sessionSlice';
import activityLogReducer from 'store/slices/activityLogSlice';

const rootReducer = {
  manageDataSheet: manageDataSheetReducer,
  manageSalesLog: manageSalesLogReducer,
  updateSalesLog: updateSalesLogReducer,
  viewSalesLog: viewSalesLogReducer,
  viewDataSheet: viewDataSheetReducer,
  createDataSheet: createDataSheetReducer,
  updateDataSheet: updateDataSheetReducer,
  report: getReportReducer,
  session: sessionReducer,
  activityLog: activityLogReducer,
};

export default rootReducer;
