import { salesLogsApi } from 'apis';
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

//*thunk action
const getSalesLogs = createAsyncThunk(
  'manageSalesLog/getSalesLogs',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await salesLogsApi.getSalesLogs();

      const listSalesLogs = response.data.map(item => {
        return {
          id: item.id,
          fullName: item.insuredPerson.firstName + ' ' + item.insuredPerson.middleName + ' ' + item.insuredPerson.lastName,
          product: item.newApplication.product,
          productDetail: item.newApplication.productDetail,
          productionDate: item.salesLog.productionDate ? item.salesLog.productionDate : null,
          homeAddress: item.insuredPerson.homeAddress,
          policy: item.salesLog.policy,
          company: item.newApplication.company,
          faceAmount: item.newApplication.faceAmount,
          referAgent: item.referAgent,
          typeOfReferAgent: item.typeOfReferAgent,
          writingAgent: item.writingAgent,
          processorAgent: item.processorAgent,
          vNProcessor: item.salesLog.vNProcessor,
          submittedDate: item.salesLog.submittedDate ? item.salesLog.submittedDate : null,
          medicalExamDate: item.medicalExam.medicalExamDate ? item.medicalExam.medicalExamDate : null,
          medicalExamStatus: item.medicalExam.medicalExamStatus ? item.medicalExam.medicalExamStatus : 'n/a',
          medicalRecordDate: item.salesLog.medicalRecordDate ? item.salesLog.medicalRecordDate : null,
          medicalRecordStatus: item.salesLog.medicalRecordStatus ? item.salesLog.medicalRecordStatus : 'n/a',
          medicalRecordCompletedDate: item.salesLog.medicalRecordCompletedDate ? item.salesLog.medicalRecordCompletedDate : null,
          quotedPremium: item.newApplication.quotedPremium,
          approvedPremium: item.salesLog.approvedPremium,
          decisionDate: item.salesLog.decisionDate ? item.salesLog.decisionDate : null,
          approvedOrDeclined: item.salesLog.approvedOrDeclined ? item.salesLog.approvedOrDeclined : 'n/a',
          reason: item.salesLog.reason,
          requestIssueDate: item.salesLog.requestIssueDate ? item.salesLog.requestIssueDate : null,
          policyArrived: item.salesLog.policyArrived ? item.salesLog.policyArrived : null,
          deliveryDate: item.salesLog.deliveryDate ? item.salesLog.deliveryDate : null,
          effectiveDate: item.salesLog.effectiveDate ? item.salesLog.effectiveDate : null,
          annualPayment: item.salesLog.annualPayment,
          targetAnnualPayment: item.salesLog.targetAnnualPayment,
          placeInforceDate: item.salesLog.placeInforceDate ? item.salesLog.placeInforceDate : null,
          finalStatus: item.salesLog.finalStatus ? item.salesLog.finalStatus : 'n/a',
          reasonFinal: item.salesLog.reasonFinal,
          masterLog: item.salesLog.masterLog,
          closeDate: item.salesLog.closeDate ? item.salesLog.closeDate : null,
          creationUserId: item.creationUserId
        };
      });

      return listSalesLogs;
    } catch (err) {
      const newError = { ...err };
      const payload = {
        error: newError.response.data
      };
      return props.rejectWithValue(payload);
    }
  }
);

const getDropdownConfig = createAsyncThunk(
  'manageSalesLog/getDropdownConfig',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await salesLogsApi.getDropdownConfig();
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const updateSalesLogCell = createAsyncThunk(
  'manageSalesLog/updateSalesLogCell',
  // eslint-disable-next-line no-unused-vars
  async (data, props) => {
    try {
      const response = await salesLogsApi.postUpdateSalesLogCell(
        data.insuredId,
        data
      );
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const refreshSalesLog = createAsyncThunk(
  'manageSalesLog/refreshSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (insuredId, props) => {
    try {
      const response = await salesLogsApi.postRefreshSalesLog(insuredId);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const downloadSalesLog = createAsyncThunk(
  'manageSalesLog/downloadSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await salesLogsApi.downloadSalesLog(id);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const duplicateSalesLog = createAsyncThunk(
  'manageSalesLog/duplicateSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await salesLogsApi.duplicateSalesLog(id);
      return {
        id: response.data.insured.id,
        fullName: response.data.insuredPersons[0].firstName + ' ' + response.data.insuredPersons[0].middleName + ' ' + response.data.insuredPersons[0].lastName,
        product: response.data.newApplication.product,
        productionDate: response.data.salesLog.productionDate ? response.data.salesLog.productionDate : null,
        homeAddress: response.data.insuredPersons[0].homeAddress,
        policy: response.data.salesLog.policy,
        company: response.data.newApplication.company,
        faceAmount: response.data.newApplication.faceAmount,
        referAgent: response.data.insured.referAgent,
        writingAgent: response.data.insured.writingAgent,
        processorAgent: response.data.insured.processorAgent,
        vNProcessor: response.data.salesLog.vNProcessor,
        submittedDate: response.data.salesLog.submittedDate ? response.data.salesLog.submittedDate : null,
        medicalExamDate: response.data.medicalExams[response.data.medicalExams.length - 1].medicalExamDate ? response.data.medicalExams[response.data.medicalExams.length - 1].medicalExamDate : null,
        medicalExamStatus: response.data.medicalExams[response.data.medicalExams.length - 1].medicalExamStatus ? response.data.medicalExams[response.data.medicalExams.length - 1].medicalExamStatus : 'n/a',
        medicalRecordDate: response.data.salesLog.medicalRecordDate ? response.data.salesLog.medicalRecordDate : null,
        medicalRecordStatus: response.data.salesLog.medicalRecordStatus ? response.data.salesLog.medicalRecordStatus : 'n/a',
        medicalRecordCompletedDate: response.data.salesLog.medicalRecordCompletedDate ? response.data.salesLog.medicalRecordCompletedDate : null,
        quotedPremium: response.data.newApplication.quotedPremium,
        approvedPremium: response.data.salesLog.approvedPremium,
        decisionDate: response.data.salesLog.decisionDate ? response.data.salesLog.decisionDate : null,
        approvedOrDeclined: response.data.salesLog.approvedOrDeclined ? response.data.salesLog.approvedOrDeclined : 'n/a',
        reason: response.data.salesLog.reason,
        requestIssueDate: response.data.salesLog.requestIssueDate ? response.data.salesLog.requestIssueDate : null,
        policyArrived: response.data.salesLog.policyArrived ? response.data.salesLog.policyArrived : null,
        deliveryDate: response.data.salesLog.deliveryDate ? response.data.salesLog.deliveryDate : null,
        effectiveDate: response.data.salesLog.effectiveDate ? response.data.salesLog.effectiveDate : null,
        annualPayment: response.data.salesLog.annualPayment,
        targetAnnualPayment: response.data.salesLog.targetAnnualPayment,
        placeInforceDate: response.data.salesLog.placeInforceDate ? response.data.salesLog.placeInforceDate : null,
        finalStatus: response.data.salesLog.finalStatus ? response.data.salesLog.finalStatus : 'n/a',
        reasonFinal: response.data.salesLog.reasonFinal,
        masterLog: response.data.salesLog.masterLog,
        closeDate: response.data.salesLog.closeDate ? response.data.salesLog.closeDate : null,
        creationUserId: response.data.insured.creationUserId
      };
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const deleteSalesLog = createAsyncThunk(
  'manageSalesLog/deleteSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (id, props) => {
    try {
      const response = await salesLogsApi.deleteSalesLog(id);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const checkLockedSalesLog = createAsyncThunk(
  'manageSalesLog/checkLockedSalesLog',
  // eslint-disable-next-line no-unused-vars
  async (dataChecked, props) => {
    try {
      const response = await salesLogsApi.checkLockedSalesLog(dataChecked);
      return response.data;
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (dataChecked, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const removeLockedSalesLog = createAsyncThunk(
  'manageSalesLog/removeLockedSalesLog',
  async (editTrackingId, props) => {
    try {
      if (editTrackingId) {
        const response = await salesLogsApi.removeLockedSalesLog(editTrackingId);
        return response.data;
      }

    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.status === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

const updateTimeEditTracking = createAsyncThunk(
  'manageSalesLog/updateTimeEditTracking',
  async (editTrackingId, props) => {
    try {
      if (editTrackingId) {
        const response = await salesLogsApi.updateTimeEditTracking(editTrackingId);
        return response.data;
      }
      
    } catch (err) {
      const newError = { ...err };
      const payload = { error: newError.response.data };
      return props.rejectWithValue(payload);
    }
  },
  {
    condition: (data, { getState }) => {
      if (getState().manageSalesLog.updateTimeEditTrackingStatus === apiStatus.PENDING) {
        return false;
      }
    },
    dispatchConditionRejection: false //* default false: don't dispatch reject action
  }
);

export {
  getSalesLogs,
  updateSalesLogCell,
  refreshSalesLog,
  downloadSalesLog,
  duplicateSalesLog,
  checkLockedSalesLog,
  removeLockedSalesLog,
  updateTimeEditTracking,
  getDropdownConfig,
  deleteSalesLog
};
