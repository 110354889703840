import { createSlice } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

import {
  getSalesLog,
  updateSalesLog,
  deleteInsuredPerson,
  deleteMedicalExam,
  deletePrimaryBeneficiary,
  deleteContingentBeneficiary,
  checkLockedSalesLog, 
  removeLockedSalesLog,
  updateTimeEditTracking,
  getInfoAutoCompleteUpdateSalesLog,
  getDropdownConfig
} from 'store/asyncActions/updateSalesLogAsyncAction';

//*reducer handle
const updateSalesLogSlice = createSlice({
  name: 'updateSalesLog',
  initialState: {
    status: null,
    error: null,
    message: null,

    backdrop: null,
    salesLog: null,
    dropdownConfig: null,
    liveSalesLog: null,
    
    isChangeFormValue: null,
    editTracking: { id: null },

    getSalesLogStatus : null,
    getSalesLogError : null,
    
    updateSalesLogStatus : null,

    //* if user status for updateTimeEditTracking -> when A in form update, B refresh -> A update form value -> system can't updateTimeEditTracking because not fount editTracking
    updateTimeEditTrackingStatus: null,
    updateTimeEditTrackingError: null,

    infoAutoComplete: null,
    companiesStatus:null,
    
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    setIsChangeFormValue: (state, action) => {
      state.isChangeFormValue = true;
    },

    // eslint-disable-next-line no-unused-vars
    updateLiveSalesLog: (state, action) => {
      state.liveSalesLog = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    clearStateUpdateSalesLog: (state, action) => {
      state.salesLog = null;
      state.dropdownConfig = null;
      state.liveSalesLog = null;
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
      state.editTracking = { id: null };
      state.isChangeFormValue = null;

      state.getSalesLogStatus = null;
      state.getSalesLogError = null;
      
      state.updateSalesLogStatus = null;

      state.updateTimeEditTrackingStatus = null;
      state.updateTimeEditTrackingError = null;
      state.infoAutoComplete = null;
      state.companiesStatus = null;
    },
  },
  extraReducers: {
    //* get sales log by id
    // eslint-disable-next-line no-unused-vars
    [getSalesLog.pending]: (state, action) => {
      state.backdrop = apiStatus.PENDING; 
      state.getSalesLogStatus = apiStatus.PENDING; 
    },
    [getSalesLog.fulfilled]: (state, action) => {
      state.backdrop = null;
      state.getSalesLogStatus = apiStatus.SUCCESS; 
      state.salesLog = action.payload;
      state.liveSalesLog = action.payload;
    },
    [getSalesLog.rejected]: (state, action) => {
      state.backdrop = null;
      state.getSalesLogStatus = apiStatus.ERROR;
      state.getSalesLogError = action.payload.error.message;
    },

    //* get list sales log
    // eslint-disable-next-line no-unused-vars
    [getDropdownConfig.pending]: (state, action) => {
      state.getSalesLogStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getDropdownConfig.fulfilled]: (state, action) => {
      state.getSalesLogStatus = apiStatus.SUCCESS;
      state.backdrop = null;
      let dataDropdown = {
        carrier: [],
        product: [],
        saleAgent: [],
        writingAgent: [],
        usProcessor: [],
        vnProcessor: []
      }
      if(action.payload.length > 0) {
        action.payload.forEach(item => {
          if(item.type === 'carrier'){
            dataDropdown.carrier.push(item.value)
          }
          if(item.type === 'product'){
            dataDropdown.product.push(item.value)
          }
          if(item.type === 'saleAgent'){
            dataDropdown.saleAgent.push(item.value)
          }
          if(item.type === 'writingAgent'){
            dataDropdown.writingAgent.push(item.value)
          }
          if(item.type === 'usProcessor'){
            dataDropdown.usProcessor.push(item.value)
          }
          if(item.type === 'vnProcessor'){
            dataDropdown.vnProcessor.push(item.value)
          }
        })
      }
      state.dropdownConfig = dataDropdown;
    },
    [getDropdownConfig.rejected]: (state, action) => {
      state.getSalesLogStatus = apiStatus.ERROR;
      state.backdrop = null;
      state.getSalesLogError = action.payload.error.message;
    },

    //* check locked sales log
    // eslint-disable-next-line no-unused-vars
    [checkLockedSalesLog.pending]: (state, action) => {
    },
    // eslint-disable-next-line no-unused-vars
    [checkLockedSalesLog.fulfilled]: (state, action) => {
      state.editTracking = action.payload.isReadyToUpdate ? action.payload.editTracking : { id: null };
    },
    [checkLockedSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },
    
    //* remove locked sales log
    // eslint-disable-next-line no-unused-vars
    [removeLockedSalesLog.pending]: (state, action) => {
    },
    // eslint-disable-next-line no-unused-vars
    [removeLockedSalesLog.fulfilled]: (state, action) => {
      state.editTracking = { id: null };
    },
    [removeLockedSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* update time edit tracking
    // eslint-disable-next-line no-unused-vars
    [updateTimeEditTracking.pending]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.PENDING
    },
    // eslint-disable-next-line no-unused-vars
    [updateTimeEditTracking.fulfilled]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.SUCCESS
    },
    [updateTimeEditTracking.rejected]: (state, action) => {
      state.updateTimeEditTrackingStatus = apiStatus.ERROR;
      state.updateTimeEditTrackingError = action.payload.error.message;
    },

    //* update sales log
    // eslint-disable-next-line no-unused-vars
    [updateSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [updateSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Update sales log success';
      state.updateSalesLogStatus = apiStatus.SUCCESS;
    },
    [updateSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete insured person
    // eslint-disable-next-line no-unused-vars
    [deleteInsuredPerson.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteInsuredPerson.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Delete insured success';
      if (action.payload) {
        const index = state.salesLog.insuredPersons.findIndex((item) => item.id === action.payload);
        state.salesLog.insuredPersons.splice(index, 1);
      }
    },
    [deleteInsuredPerson.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete medical exam
    // eslint-disable-next-line no-unused-vars
    [deleteMedicalExam.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteMedicalExam.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Delete medical exam success';
      if (action.payload) {
        const index = state.salesLog.medicalExams.findIndex((item) => item.id === action.payload);
        state.salesLog.medicalExams.splice(index, 1);
      }
    },
    [deleteMedicalExam.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete primary beneficiary
    // eslint-disable-next-line no-unused-vars
    [deletePrimaryBeneficiary.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deletePrimaryBeneficiary.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Delete primary beneficiary success';
      if (action.payload) {
        const index = state.salesLog.primaryBeneficiarys.findIndex((item) => item.id === action.payload);
        state.salesLog.primaryBeneficiarys.splice(index, 1);
      }
    },
    [deletePrimaryBeneficiary.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* delete contingent beneficiary
    // eslint-disable-next-line no-unused-vars
    [deleteContingentBeneficiary.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
    },
    // eslint-disable-next-line no-unused-vars
    [deleteContingentBeneficiary.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.message = 'Delete contingent beneficiary success';
      if (action.payload) {
        const index = state.salesLog.contingentBeneficiarys.findIndex((item) => item.id === action.payload);
        state.salesLog.contingentBeneficiarys.splice(index, 1);
      }
    },
    [deleteContingentBeneficiary.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.error = action.payload.error.message;
    },

    //* Handle Auto Complete
    // eslint-disable-next-line no-unused-vars
    [getInfoAutoCompleteUpdateSalesLog.pending]: (state, action) => {
      state.companiesStatus = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING;
    },
    [getInfoAutoCompleteUpdateSalesLog.fulfilled]: (state, action) => {
      state.companiesStatus = apiStatus.SUCCESS;
      state.backdrop = apiStatus.SUCCESS;
      state.message = 'Get info auto complete success';
      state.infoAutoComplete = action.payload;
    },
    [getInfoAutoCompleteUpdateSalesLog.rejected]: (state, action) => {
      state.companiesStatus = apiStatus.ERROR;
      state.error = action.payload.error.message;
      state.backdrop = apiStatus.SUCCESS;
    },  
  }
});

const { actions, reducer } = updateSalesLogSlice;
const {
  setIsChangeFormValue,
  updateLiveSalesLog,
  clearStateUpdateSalesLog,
} = actions;

export {
  setIsChangeFormValue,
  updateLiveSalesLog,
  clearStateUpdateSalesLog,
}
export default reducer;
