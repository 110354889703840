import axios from 'apis/axiosClient';

const getDataSheets = () => {
  return axios.get('/data-sheets/');
}

const getListDataSheetById = (id, action = null) => {
  return axios.get(`/data-sheets/${id}?action=${action}`);
}

const createDataSheet = (addDataSheetDto) => {
  return axios.post('/data-sheets/', addDataSheetDto);
}

const updateDataSheet = (data,id) => {
  return axios.patch(`/data-sheets/${id}`, data);
}

const deleteInsuredPerson = (insuredPersonId, insuredId) => {
  return axios.delete(`insured-persons/${insuredPersonId}/insureds/${insuredId}`);
}

const deleteMedicalExam = (medicalExamId, insuredId) => {
  return axios.delete(`medical-exams/${medicalExamId}/insureds/${insuredId}`);
}

const deletePrimaryBeneficiary = (primaryBeneficiaryId, insuredId) => {
  return axios.delete(`primary-beneficiarys/${primaryBeneficiaryId}/insureds/${insuredId}`);
}

const deleteContingentBeneficiary = (contingentBeneficiaryId, insuredId) => {
  return axios.delete(`contingent-beneficiarys/${contingentBeneficiaryId}/insureds/${insuredId}`);
}

const submitDataSheet = (id) => {
  return axios.patch(`/data-sheets/${id}/submit`);
}

const duplicateDataSheet = (id) => {
  return axios.post(`/data-sheets/${id}/duplicate`);
}

const deleteDataSheet = (id) => {
  return axios.delete(`data-sheets/${id}`);
}
const checkLockedDataSheet = dataChecked => {
  return axios.post('/edit-tracking/', dataChecked);
};

const removeLockedDataSheet = editTrackingId => {
  return axios.delete(`/edit-tracking/${editTrackingId}`);
};

const updateTimeEditTracking = editTrackingId => {
  return axios.patch(`/edit-tracking/${editTrackingId}`);
};

const getAutoComplete = () => {
  return axios.get('/data-sheets/auto-complete/agent');
}

export default {
  getListDataSheetById,
  createDataSheet,
  updateDataSheet,
  deleteInsuredPerson,
  deleteMedicalExam,
  deletePrimaryBeneficiary,
  deleteContingentBeneficiary,
  getDataSheets,
  submitDataSheet,
  duplicateDataSheet,
  deleteDataSheet,
  checkLockedDataSheet,
  removeLockedDataSheet,
  updateTimeEditTracking,
  getAutoComplete
}
