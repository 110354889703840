import { createSlice } from '@reduxjs/toolkit';
import apiStatus from 'utils/apiStatus';

import {
  getSalesLog,
} from 'store/asyncActions/viewSalesLogAsyncAction';

//*reducer handle
const viewSalesLogSlice = createSlice({
  name: 'viewSalesLog',
  initialState: {
    status: null,
    error: null,
    message: null,

    backdrop: null,
    salesLog: null,
  },
  reducers: {
    // eslint-disable-next-line no-unused-vars
    clearStateViewSalesLog: (state, action) => {
      state.salesLog = null;
      state.status = null;
      state.error = null;
      state.message = null;
      state.backdrop = null;
    },
  },
  extraReducers: {
    //* get sales log by id
    // eslint-disable-next-line no-unused-vars
    [getSalesLog.pending]: (state, action) => {
      state.status = apiStatus.PENDING;
      state.backdrop = apiStatus.PENDING; 
    },
    [getSalesLog.fulfilled]: (state, action) => {
      state.status = apiStatus.SUCCESS;
      state.backdrop = null;
      state.salesLog = action.payload;
    },
    [getSalesLog.rejected]: (state, action) => {
      state.status = apiStatus.ERROR;
      state.backdrop = null;
      state.error = action.payload.error.message;
    },
  }
});

const { actions, reducer } = viewSalesLogSlice;
const { 
  clearStateViewSalesLog,
} = actions;

export {
  clearStateViewSalesLog,
}
export default reducer;
